import { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getEditValues } from '../form';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';
import MedicationModal from '../../popups/MedicationModal';

import { ICarePlanMedication } from '~/services/api/carePlanMedications/types';
import { IFormValues } from '../../popups/MedicationModal/form/types';
import { IPagePermissions } from '../../../types';
import styles from './Item.module.scss';
import { isoToFormat } from '~/utils/date';

interface IProps {
  item: ICarePlanMedication;
  permissions: IPagePermissions;
  onCarePlanChange: () => void;
}

const Item = ({ item, permissions, onCarePlanChange }: IProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { onUpdateCarePlanMedication, onRemoveCarePlanMedication } = useStoreActions(
    actions => actions.carePlanMedications,
  );
  const { showError } = useStoreActions(actions => actions.snackbar);

  const handleUpdateMedication = async (values: IFormValues) => {
    const payload = {
      ...values,
      id: item.id,
      startDate: values.startDate === '' ? null : values.startDate,
      endDate: values.endDate === '' ? null : values.endDate,
    };

    try {
      setIsUpdating(true);
      await onUpdateCarePlanMedication(payload);
      onCarePlanChange();
      setShowEdit(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemoveMedication = async () => {
    try {
      setIsRemoving(true);
      await onRemoveCarePlanMedication(item.id);
      onCarePlanChange();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{item.name}</TableCell>
        <TableCell>
          {item.startDate === null ? '-' : isoToFormat(item.startDate, 'dd MMM y')}
        </TableCell>
        <TableCell>{item.endDate === null ? '-' : isoToFormat(item.endDate, 'dd MMM y')}</TableCell>
        <TableCell>
          <ReadMoreLess content={item.reason} />
        </TableCell>
        {permissions.canManage && (
          <TableCell align="right">
            <IconButton
              className={styles.iconButton}
              title="Edit"
              onClick={() => setShowEdit(true)}
            >
              <CreateIcon className={styles.icon} />
            </IconButton>
            <IconButton
              className={styles.iconButton}
              title="Remove"
              onClick={() => setShowRemove(true)}
            >
              <DeleteIcon className={styles.icon} />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {showEdit && (
        <MedicationModal
          mode="edit"
          initialValues={getEditValues(item)}
          isProcessing={isUpdating}
          onSubmit={handleUpdateMedication}
          onClose={() => setShowEdit(false)}
        />
      )}
      {showRemove && (
        <ConfirmModal
          isLoading={isRemoving}
          title="Remove Medication"
          description="Are you sure you want to remove current Medication?"
          onClose={() => setShowRemove(false)}
          onConfirm={handleRemoveMedication}
        />
      )}
    </>
  );
};

export default Item;
