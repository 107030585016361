import { useEffect, useState, useMemo, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import EventIcon from '@mui/icons-material/Event';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import {
  formatToIsoWithoutTimezone,
  getLastWeekDateRange,
  getLastMonthDateRange,
} from '~/utils/date';
import downloadFile from '~/utils/downloadFile';
import { getFileNameFromContentDisposition } from '~/utils/headers';
import { extractErrorMessage } from '~/utils/error';
import { getInitialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import SelectAll from '~/ui/components/inputs/SelectAll';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Checkbox from '~/ui/components/inputs/Checkbox';

import { IPatientSummaryReportRequest } from '~/services/api/reports/types';
import { IFormValues } from './types';
import { DateRangeType } from '../types';
import styles from './PatientSummary.module.scss';
import { IIdName } from '~/services/api/types';

const PatientSummary = (): ReactElement => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [patients, setPatients] = useState<IIdName[]>([]);
  const [isProgramsLoading, setIsProgramsLoading] = useState(false);
  const [isPatientsLoading, setIsPatientsLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const initialValues = getInitialValues();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const programId = watch('programId');

  const programOptions = useMemo(
    () =>
      programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [programs.length],
  );

  const patientOptions = patients.map(item => ({
    value: String(item.id),
    label: item.name,
  }));

  const getDropdownOptions = async () => {
    if (!programId) {
      setPatients([]);
      return;
    }

    setIsPatientsLoading(true);

    const patientsList = await api.reports.getProgramPatients({ programId }).then(res => res.data);

    setPatients(patientsList);
    setIsPatientsLoading(false);
  };

  const generateReport = async (payload: IPatientSummaryReportRequest) => {
    try {
      setIsProcessing(true);
      const { data, headers } = await api.reports.getPatientSummaryReport(payload);
      const fileName = getFileNameFromContentDisposition(headers['content-disposition']);
      downloadFile(data, fileName);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsProcessing(false);
    }
  };

  const onSubmitForm = (values: IFormValues) => {
    const payload = { ...values };
    delete payload.programId;

    generateReport({
      ...payload,
      startDate: formatToIsoWithoutTimezone(payload.startDate),
      endDate: formatToIsoWithoutTimezone(payload.endDate),
    });
  };

  const resetDateRange = (type: DateRangeType) => {
    let dateRange = getLastWeekDateRange();

    if (type === DateRangeType.Month) {
      dateRange = getLastMonthDateRange();
    }

    setValue('startDate', dateRange.startDate);
    setValue('endDate', dateRange.endDate);
  };

  const onMount = async () => {
    try {
      setIsProgramsLoading(true);
      const selectors = await api.reports.getSelectors().then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsProgramsLoading(false);
    }
  };

  useEffect(() => {
    getDropdownOptions();
    setValue('patientIds', []);
  }, [programId]);

  useEffect(() => {
    onMount();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className={styles.title}>Patient Summary</div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectVirtualized
            name="programId"
            control={control}
            errors={errors}
            options={programOptions}
            isLoading={isProgramsLoading}
            label="Program Name *"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <SelectAll
            name="patientIds"
            control={control}
            errors={errors}
            options={patientOptions}
            isLoading={isPatientsLoading}
            label="Patient Name *"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <div className={styles.blockTitle}>Charts</div>
          <Checkbox
            name="includeReadings"
            control={control}
            errors={errors}
            label="Historical Readings"
          />
          <Checkbox name="includeCharts" control={control} errors={errors} label="Readings Graph" />
          <Checkbox name="includeNotes" control={control} errors={errors} label="Notes" />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <DatePicker
            placeholder=".. /.. /.."
            label="Date From"
            name="startDate"
            maxDate={new Date()}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            placeholder=".. /.. /.."
            label="Date To"
            name="endDate"
            maxDate={new Date()}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<EventIcon />}
            onClick={() => resetDateRange(DateRangeType.Week)}
          >
            Last Week
          </Button>
          <div className={styles.spacer} />
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<EventIcon />}
            onClick={() => resetDateRange(DateRangeType.Month)}
          >
            Last Month
          </Button>
        </Grid>
        <Grid item xs={6} className={styles.rightBtnCol}>
          <Button
            type="button"
            variant="outlined"
            startIcon={<RotateRightIcon />}
            onClick={() => {
              reset(initialValues);
            }}
          >
            Reset
          </Button>
          <div className={styles.spacer} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Generate
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PatientSummary;
