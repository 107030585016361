import { useState, ReactElement } from 'react';
import { Grid, Switch } from '@material-ui/core';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import styles from './EditTableModal.module.scss';
import { TableCustomizationType } from '~/services/api/enums';
import Button from '~/ui/components/common/Button';
import { ICell } from './types';
import { ITableCustomization } from '~/services/api/tableCustomizations/types';

interface IProps {
  typeId: TableCustomizationType;
  title: string;
  cells: { [key: string]: ICell };
  onClose: () => void;
}

const EditTableModal = ({ typeId, title, cells, onClose }: IProps): ReactElement => {
  const [isUpdating, setIsUpdating] = useState(false);

  const initialTableCustomizations = useStoreState(state => state.tableCustomizations.items)[
    typeId
  ];

  const [tableCustomizations, setTableCustomizations] = useState([...initialTableCustomizations]);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onUpdateTableCustomizationsByTypeId } = useStoreActions(
    actions => actions.tableCustomizations,
  );

  const switchIsVisible = (tableCustomization: ITableCustomization) => {
    setTableCustomizations(
      tableCustomizations.map(x =>
        x.columnKey === tableCustomization.columnKey ? { ...x, isVisible: !x.isVisible } : x,
      ),
    );
  };

  const onDragEnd = (dropResult: DropResult) => {
    const newTableCustomizations = [...tableCustomizations];

    const moved = newTableCustomizations.splice(dropResult.source.index, 1)[0];
    newTableCustomizations.splice(dropResult.destination.index, 0, moved);

    setTableCustomizations(newTableCustomizations);
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      await onUpdateTableCustomizationsByTypeId({ typeId, items: tableCustomizations });
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUpdating(false);
    }
  };

  return (
    <Modal open title={title} maxWidth="sm" className={styles.modal} onClose={onClose}>
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="editTable">
            {providedDroppable => (
              <Grid
                container
                className={styles.dragListContainer}
                direction="column"
                spacing={1}
                {...providedDroppable.droppableProps}
                innerRef={providedDroppable.innerRef}
              >
                <Grid
                  container
                  className={styles.dragPlaceholderContainer}
                  direction="column"
                  spacing={1}
                >
                  {tableCustomizations.map(tableCustomization => (
                    <Grid key={tableCustomization.columnKey} item>
                      <Grid container className={styles.dragPlaceholderItem} />
                    </Grid>
                  ))}
                </Grid>
                {tableCustomizations.map((tableCustomization, index) => (
                  <Draggable
                    key={tableCustomization.columnKey}
                    draggableId={tableCustomization.columnKey}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        item
                        className={styles.dragItem}
                        {...provided.draggableProps}
                        innerRef={provided.innerRef}
                      >
                        <Grid
                          container
                          className={classnames(styles.dragContainer, {
                            [styles.isDragging]: snapshot.isDragging,
                          })}
                          alignItems="center"
                        >
                          <Grid item className={styles.dragHandler} {...provided.dragHandleProps} />
                          <Grid item xs className={styles.columnLabel}>
                            {cells[tableCustomization.columnKey].label}
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              color="primary"
                              checked={tableCustomization.isVisible}
                              onChange={() => switchIsVisible(tableCustomization)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
        <Grid container className={styles.buttons} justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="default" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isUpdating}
              disabled={isUpdating}
              onClick={handleUpdate}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
};

export default EditTableModal;
