import { Grid, IconButton } from '@material-ui/core';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutline } from '@mui/icons-material';
import { useEffect } from 'react';
import { IMobileUserInfo } from '~/services/api/mobileUsers/types';
import Button from '~/ui/components/common/Button';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag/PhoneFlagInput';
import styles from './HomeHealthcareSection.module.scss';
import Checkbox from '~/ui/components/inputs/Checkbox';
import RadioGroup from '~/ui/components/inputs/RadioGroup';
import homeHealthCareOptions from '~/ui/constants/homeHealthCareOptions';
import api from '~/services/api';
import Input from '~/ui/components/inputs/Input';

interface IFormFields extends FieldValues {
  homeHealthcarePeople: IMobileUserInfo[];
}

const HomeHealthcareSection = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<IFormFields>();

  const { fields, append, remove } = useFieldArray<IFormFields, 'homeHealthcarePeople', 'id'>({
    control,
    name: 'homeHealthcarePeople',
  });

  const isHomeHealthCare = watch('isHomeHealthCare');

  useEffect(() => {
    if (Number(isHomeHealthCare) === 0) {
      remove();
    }
  }, [isHomeHealthCare]);

  const onPhoneInputBlur = async (value: string, index: number) => {
    try {
      await api.mobileUsers.getByPhoneNumber(value).then(response => {
        if (response.data != null) {
          setValue(`homeHealthcarePeople.${index}.hasAgreed`, response.data.hasAgreed);
          setValue(
            `homeHealthcarePeople.${index}.mobileUserRoleId`,
            response.data.mobileUserRoleId,
          );
        }
      });
    } catch (e) {
      setValue(`homeHealthcarePeople.${index}.hasAgreed`, false);
    }
  };

  const registerWithoutRef = (controlName: 'phone' | 'hasAgreed', index: number) => {
    const { ref, ...rest } = register(`homeHealthcarePeople.${index}.${controlName}` as const);

    return rest;
  };

  const onAdd = () => {
    append({
      id: Date.now() * -1,
      phone: '',
      hasAgreed: false,
    });
  };

  return (
    <>
      <Grid container className={styles.titleContainer} spacing={4} alignItems="center">
        <Grid item className={styles.title}>
          Home Healthcare
        </Grid>
        <Grid item>
          <RadioGroup
            name="isHomeHealthCare"
            control={control}
            errors={errors}
            options={homeHealthCareOptions}
          />
        </Grid>
        <Grid item xs={4}>
          {Number(isHomeHealthCare) === 1 && (
            <Input
              type="number"
              name="hospitalizationRiskScore"
              register={register}
              errors={errors}
              label="Hospitalization Risk Score (%)"
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {Number(isHomeHealthCare) === 1 && (
          <>
            {fields.map((item, index) => (
              <Grid item xs={12} key={item.id}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <PhoneFlagInput
                      control={control}
                      name={`homeHealthcarePeople${index}phoneNumber`}
                      errors={errors}
                      {...registerWithoutRef('phone', index)}
                      disabled={item.id >= 0}
                      onInputBlur={value => onPhoneInputBlur(value, index)}
                    />
                  </Grid>

                  <Grid item xs={4} className={styles.controlButtons}>
                    <IconButton
                      className={styles.icon}
                      title="Remove"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Checkbox
                      name={`homeHealthcarePeople${index}hasAgreed`}
                      control={control}
                      errors={errors}
                      label="T&C/HIPAA"
                      {...registerWithoutRef('hasAgreed', index)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<AddCircleOutline />}
                  onClick={onAdd}
                >
                  Add More
                </Button>
              </span>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default HomeHealthcareSection;
