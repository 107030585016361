import axios from 'axios';
import { IResponse } from '~/services/api/types';

const apiUrl = 'readings';

export const remove = (ids: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: apiUrl,
    data: ids,
  });

export default {};
