import { useEffect, useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getInitialValues } from './form';
import Loader from '~/ui/components/common/Loader';
import { IFormValues } from './types';
import TemplateForm from './TemplateForm';

interface IProps {
  templateId: number;
  onClose: () => void;
}

const TemplateModal = ({ templateId, onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const template = useStoreState(state => state.templates.item);
  const { onGetTemplate, onCreateTemplate, onUpdateTemplate } = useStoreActions(
    actions => actions.templates,
  );
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const onSaveClick = async (data: IFormValues) => {
    setIsSaving(true);

    try {
      if (templateId === null) {
        await onCreateTemplate(data);

        showNotify('Template successfully created');
      } else {
        const payload = {
          ...data,
          id: templateId,
        };

        await onUpdateTemplate(payload);

        showNotify('Template successfully updated');
      }

      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    if (isSaving) {
      return;
    }

    onClose();
  };

  const onMount = async () => {
    try {
      if (templateId !== null) {
        await onGetTemplate(templateId);
      }

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal
      open
      title={`${templateId === null ? 'Add' : 'Update'} Template`}
      maxWidth="md"
      onClose={handleClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <TemplateForm
          initialValues={getInitialValues(templateId === null ? null : template)}
          isSaving={isSaving}
          onSaveClick={onSaveClick}
          onCancelClick={handleClose}
        />
      )}
    </Modal>
  );
};

export default TemplateModal;
