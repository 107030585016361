import setTemplates from './setTemplates';
import setMoreTemplates from './setMoreTemplates';
import reset from './reset';
import addTemplate from './addTemplate';
import updateTemplate from './updateTemplate';
import deleteTemplate from './deleteTemplate';
import setTemplatesByTypeId from './setTemplatesByTypeId';
import setTemplate from './setTemplate';

export default {
  setTemplate,
  setTemplates,
  setMoreTemplates,
  setTemplatesByTypeId,
  reset,
  addTemplate,
  updateTemplate,
  deleteTemplate,
};
