import { TemplateType } from '~/services/api/enums';
import { ITemplatesStore } from './types';

const initStore: ITemplatesStore = {
  item: null,
  items: [],
  itemsByTypeId: {
    [TemplateType.Notes]: [],
    [TemplateType.Goals]: [],
    [TemplateType.Interventions]: [],
  },
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
