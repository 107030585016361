import { getLastMonthDateRange } from '~/utils/date';
import { IFormValues } from '../types';

const getInitialValues = (): IFormValues => ({
  includeReadings: false,
  includeCharts: false,
  includeNotes: false,
  ...getLastMonthDateRange(),
});

export default getInitialValues;
