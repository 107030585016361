import { ITemplate } from '~/services/api/templates/types';
import { IFormValues } from '../types';
import { TemplateType } from '~/services/api/enums';

const getInitialValues = (template: ITemplate): IFormValues => ({
  name: template?.name ?? '',
  typeId: template?.typeId ?? TemplateType.Notes,
  content: template?.content ?? '',
});

export default getInitialValues;
