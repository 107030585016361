import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { getSort } from '~/utils/sort';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { headCells } from './constants';
import { ITemplateList } from '~/services/api/templates/types';
import { ISort } from '~/utils/sort/types';
import { Order } from '~/types';

interface IProps {
  items: ITemplateList[];
  sort: ISort;
  setSort: (sort: ISort) => void;
}

const TableComponent = ({ items, sort, setSort }: IProps): ReactElement => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell key={headCell.id} width={headCell.width} align={headCell.align || 'inherit'}>
              {headCell.useSort ? (
                <TableSortLabel
                  active={sort.orderBy === headCell.id}
                  direction={
                    sort.orderBy === headCell.id && sort.isDescending ? Order.Desc : Order.Asc
                  }
                  onClick={() => setSort(getSort(sort, headCell.id))}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.length > 0 ? (
          items.map(item => <Item key={item.id} item={item} />)
        ) : (
          <TableRow>
            <TableCell colSpan={6}>
              <NoDataFound />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableComponent;
