import { ReactElement, useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getDefaultValues, getInitialValues, validate } from './form';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import SelectAll from '~/ui/components/inputs/SelectAll';

import { homeHealthCareOptions } from './constants';
import { IIdName } from '~/services/api/types';
import { IPatientsListFilters } from '~/services/api/patients/types';
import DatePicker from '~/ui/components/inputs/DatePicker';
import { patientStatusOptions } from '~/ui/constants/patient';
import Button from '~/ui/components/common/Button';

interface IProps {
  onSubmit: (filters: IPatientsListFilters) => void;
}

const Header = ({ onSubmit }: IProps): ReactElement => {
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [managers, setManagers] = useState<IIdName[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isManagersLoading, setIsManagersLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const formMethods = useForm<IPatientsListFilters>({
    resolver: validate,
    defaultValues: getInitialValues(),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const programId = watch('programId');

  const programOptions = useMemo(() => {
    const options = programs.map(item => ({
      value: item.id,
      label: item.name,
    }));

    return [
      {
        value: '',
        label: 'All Programs',
      },
      ...options,
    ];
  }, [programs.length]);

  const managerOptions = useMemo(
    () =>
      managers.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [managers],
  );

  const getManagerOptions = async () => {
    try {
      setIsManagersLoading(true);

      let managersList = [];

      if (programId) {
        managersList = await api.patients
          .getSelectorsById(0, programId)
          .then(res => res.data.users);
      } else {
        managersList = await api.patients.getSelectors().then(res => res.data.users);
      }

      setManagers(managersList);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsManagersLoading(false);
    }
  };

  const handleOnSubmit = (values: IPatientsListFilters) => {
    const payload = { ...values };

    localStorage.setItem('patientsFilters', JSON.stringify(payload));

    onSubmit(payload);
  };

  const onMount = async () => {
    setIsLoading(true);

    try {
      const selectors = await api.patients.getSelectors().then(res => res.data);
      setPrograms(selectors.programs);
      setManagers(selectors.users);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    if (isLoading) return; // avoid call getManagerOptions() on page mount
    // reset managerOptions for dropdown refresh
    // and immediately reset (hide) SelectAll label (if active)
    setManagers([]);
    getManagerOptions();
    setValue('healthCareManagerIds', []);
  }, [programId]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="programId"
              control={control}
              errors={errors}
              options={programOptions}
              isLoading={isLoading}
              label="Program Name"
            />
          </Grid>
          <Grid item xs>
            <Input name="fullName" register={register} errors={errors} label="Patient Name" />
          </Grid>
          <Grid item xs>
            <SelectAll
              name="healthCareManagerIds"
              control={control}
              errors={errors}
              options={managerOptions}
              isLoading={isLoading || isManagersLoading}
              label="Healthcare Manager"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="isHomeHealthCare"
              control={control}
              errors={errors}
              options={homeHealthCareOptions}
              label="Home Health Care"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="status"
              control={control}
              errors={errors}
              options={patientStatusOptions}
              label="Status"
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                const defaultValues = getDefaultValues();

                reset(defaultValues);
                handleOnSubmit(defaultValues);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
