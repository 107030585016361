import { AxiosResponse } from 'axios';
import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { ITokens } from '~/services/api/auth/types';
import { deleteTokens, getClientId, getRefreshToken, setTokens } from '~/services/auth';
import { IAuthStore, IThunkRefresh } from '../types';

let refreshPromise: Promise<AxiosResponse<ITokens>> = null;

const onRefresh: IThunkRefresh = thunk(
  // @ts-ignore
  async ({ setAuthorized }, _, { getState }) => {
    const clientId = getClientId();
    const refreshToken = getRefreshToken();

    const localState = getState() as IAuthStore;

    if (!refreshToken || localState.parsedRefreshToken?.exp - new Date().getTime() / 1000 < 60) {
      deleteTokens();
      setAuthorized();
      return;
    }

    try {
      refreshPromise =
        refreshPromise ??
        api.auth.refresh({
          clientId,
          refreshToken,
        });

      const data = await refreshPromise.then(response => response.data);

      setTokens(data);
    } catch (e) {
      const status = (e as AxiosResponse)?.status;

      if (status >= 400 && status < 500) {
        deleteTokens();
      }
    } finally {
      refreshPromise = null;
      setAuthorized();
    }
  },
);

export default onRefresh;
