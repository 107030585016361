import onGetTemplates from './onGetTemplates';
import onGetMoreTemplates from './onGetMoreTemplates';
import onCreateTemplate from './onCreateTemplate';
import onUpdateTemplate from './onUpdateTemplate';
import onDeleteTemplate from './onDeleteTemplate';
import onGetTemplatesByTypeId from './onGetTemplatesByTypeId';
import onGetTemplate from './onGetTemplate';

export default {
  onGetTemplate,
  onGetTemplates,
  onGetMoreTemplates,
  onGetTemplatesByTypeId,
  onCreateTemplate,
  onUpdateTemplate,
  onDeleteTemplate,
};
