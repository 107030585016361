import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';

import styles from './SubHeaderItem.module.scss';

interface IProps {
  icon: ReactElement;
  text: string;
}

const SubHeaderItem = ({ icon, text }: IProps): ReactElement => (
  <Grid container className={styles.container} alignItems="center">
    <Grid item>{icon}</Grid>
    <Grid item className={styles.text}>
      {text}
    </Grid>
  </Grid>
);

export default SubHeaderItem;
