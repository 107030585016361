import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanByPatientId } from '../types';

const onGetCarePlanByPatientId: IThunkGetCarePlanByPatientId = thunk(
  // @ts-ignore
  async ({ setCarePlan }, patientId) => {
    const { data } = await api.carePlans.getByPatientId(patientId);
    setCarePlan(data);
  },
);

export default onGetCarePlanByPatientId;
