import { ReactElement } from 'react';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { AddCircleOutline } from '@mui/icons-material';
import { PhysicianType } from '~/services/api/enums';
import Physician from './Physician';
import Button from '~/ui/components/common/Button';
import { getPhysicianInitialValues } from './form';
import { IPhysician } from '~/services/api/physicians/types';
import Divider from '../Divider';

interface IFormFields extends FieldValues {
  physicians: IPhysician[];
}

const PhysiciansSection = (): ReactElement => {
  const { control } = useFormContext<IFormFields>();

  const { fields, append, remove } = useFieldArray<IFormFields, 'physicians', ''>({
    control,
    name: 'physicians',
    keyName: '',
  });

  const attendingPhysician = fields.find(x => x.typeId === PhysicianType.Attending);

  const secondaryPhysicians = fields.filter(x => x.typeId !== PhysicianType.Attending);

  const onAdd = () => append(getPhysicianInitialValues({} as IPhysician));

  const onRemove = (index: number) => remove(index);

  const getKey = (id: number, index: number) => `${id}-${index}`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Divider title="Attending Physician" showDividerLine={false} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Physician index={fields.indexOf(attendingPhysician)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider title="Secondary Physician" showDividerLine={false} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {secondaryPhysicians.map((physician, index) => (
              <Grid item xs={12} key={getKey(physician.id, index)}>
                <Physician index={fields.indexOf(physician)} onRemove={onRemove} />
              </Grid>
            ))}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<AddCircleOutline />}
                onClick={onAdd}
              >
                Add More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PhysiciansSection;
