export const allOption = {
  value: '',
  label: 'All',
};

export const allUsersOption = {
  value: '',
  label: 'All Users',
};

export const allProgramsOption = {
  value: '',
  label: 'All Programs',
};

export const allAreasOption = {
  value: '',
  label: 'All Areas',
};

export const allEventsOption = {
  value: '',
  label: 'All Events',
};

export default {};
