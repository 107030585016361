import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkReviewCarePlanByPatientId } from '../types';

const onReviewCarePlanByPatientId: IThunkReviewCarePlanByPatientId = thunk(
  // @ts-ignore
  async ({ setCarePlan }, patientId) => {
    const { data } = await api.carePlans.reviewByPatientId(patientId);
    setCarePlan(data);
  },
);

export default onReviewCarePlanByPatientId;
