import { ReactElement } from 'react';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import GeneralLayout from '~/ui/layouts/GeneralLayout';
import PatientLayout from '~/ui/layouts/PatientLayout';
import { DASHBOARD, SIGN_IN } from '../constants/paths';
import { UserPermission, UserRole } from '~/types';
import { ICurrentUser } from '~/services/api/auth/types';

interface IProps extends RouteProps {
  component: (routeProps: RouteComponentProps) => ReactElement;
  isPatientLayout?: boolean;
  roles?: UserRole[];
  permissions?: UserPermission[];
}

const hasAccess = (currentUser: ICurrentUser, roles: UserRole[], permissions: UserPermission[]) => {
  if (currentUser.roleId === UserRole.SystemAdmin) {
    return true;
  }

  if (roles?.includes(currentUser.roleId)) {
    return true;
  }

  if (permissions?.some(x => currentUser.permissions.includes(x))) {
    return true;
  }

  if (currentUser.roleId !== UserRole.HealthManager && (roles?.length ?? 0) === 0) {
    return true;
  }

  if (
    currentUser.roleId === UserRole.HealthManager &&
    (permissions?.length ?? 0) === 0 &&
    (roles?.length ?? 0) === 0
  ) {
    return true;
  }

  return false;
};

/**
 * Render private route
 * Only authenticated users can access to private route
 * Otherwise - redirect user to another allowed page route
 */
const PrivateRoute = ({
  component: Component,
  isPatientLayout,
  roles,
  permissions,
  ...rest
}: IProps): ReactElement => {
  const { authorized, authChecked, currentUser } = useStoreState(state => state.auth);

  if (!authChecked) {
    return null;
  }

  if (!authorized) {
    return <Redirect to={SIGN_IN} />;
  }

  if (!hasAccess(currentUser, roles, permissions)) {
    return <Redirect to={DASHBOARD} />;
  }

  // switch layout type
  const Layout = isPatientLayout ? PatientLayout : GeneralLayout;

  return (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
