import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

import { Grid } from '@mui/material';
import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Table from './Table';

import styles from './Templates.module.scss';
import { ITemplatesListFilters, ITemplatesSelectors } from '~/services/api/templates/types';
import { getInitialFilters } from './Header/form';
import { ISort } from '~/utils/sort/types';
import { initialSort } from './Table/constants';
import Button from '~/ui/components/common/Button';
import TemplateModal from './TemplateModal';

const Templates = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectorsLoading, setIsSelectorsLoading] = useState(true);
  const [filters, setFilters] = useState<ITemplatesListFilters>({ ...getInitialFilters() });
  const [selectors, setSelectors] = useState<ITemplatesSelectors>(null);
  const [sort, setSort] = useState<ISort>({ ...initialSort });
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const { items, pagination } = useStoreState(state => state.templates);
  const { onGetTemplates, onGetMoreTemplates } = useStoreActions(actions => actions.templates);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const params = {
    ...filters,
    ...sort,
  };

  const getData = async () => {
    setIsLoading(true);

    try {
      await onGetTemplates(params);

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getSelectors = async () => {
    setIsSelectorsLoading(true);

    try {
      const { data } = await api.templates.getSelectors();

      setSelectors(data);

      setIsSelectorsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreTemplates(params);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getData();
  }, [filters, sort]);

  useEffect(() => {
    getSelectors();
  }, []);

  const onAddNewClick = () => {
    setIsTemplateModalOpen(true);
  };

  const onTemplateModalClose = () => {
    setIsTemplateModalOpen(false);
    getSelectors();
  };

  return (
    <>
      <Grid container className={styles.header} alignItems="center" justifyContent="space-between">
        <Grid item className={styles.title}>
          Templates
        </Grid>
        <Grid item className={styles.addButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<AddCircleOutline />}
            onClick={onAddNewClick}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      <Paper elevation={3} className={styles.container}>
        {!isSelectorsLoading && <Header selectors={selectors} onSubmit={setFilters} />}
        {!isLoading && (
          <InfiniteScroll
            dataLength={items.length}
            next={onLoadMore}
            hasMore={pagination.hasMore}
            loader={<Loader />}
          >
            <Table items={items} sort={sort} setSort={setSort} />
          </InfiniteScroll>
        )}
        {isLoading && <Loader />}
      </Paper>
      {isTemplateModalOpen && <TemplateModal templateId={null} onClose={onTemplateModalClose} />}
    </>
  );
};

export default Templates;
