import { IPatient } from '~/services/api/patients/types';
import { IPatientFormValues } from '../types';
import { PhysicianType } from '~/services/api/enums';
import { getPhysicianInitialValues } from '../PhysiciansSection/form';
import { IPhysician } from '~/services/api/physicians/types';

const getInitialValues = (patient: IPatient): IPatientFormValues => {
  const dateOfBirth = patient.dateOfBirth ? new Date(patient.dateOfBirth) : null;

  const physicians = patient.physicians.some(x => x.typeId === PhysicianType.Attending)
    ? patient.physicians
    : [
        getPhysicianInitialValues({ typeId: PhysicianType.Attending } as IPhysician),
        ...patient.physicians,
      ];

  return {
    ...patient,
    homePhone: patient.homePhone ?? '',
    cellPhone: patient.cellPhone ?? '',
    workPhone: patient.workPhone ?? '',
    emergencyContactPhone: patient.emergencyContactPhone ?? '',
    dateOfBirth: dateOfBirth
      ? new Date(
          dateOfBirth.getUTCFullYear(),
          dateOfBirth.getUTCMonth(),
          dateOfBirth.getUTCDate(),
        ).toISOString()
      : null,
    healthCareManagerIds: patient.healthCareManagers.map(item => item.id),
    isHomeHealthCare: String(Number(patient.isHomeHealthCare)),
    shippingAddressSameAsHome: String(Number(patient.shippingAddressSameAsHome)),
    physicians,
  };
};

export default getInitialValues;
