import { TemplateType } from '~/services/api/enums';

const templateTypeOptions = [
  {
    value: TemplateType.Notes,
    label: 'Notes',
  },
  {
    value: TemplateType.Goals,
    label: 'Goals',
  },
  {
    value: TemplateType.Interventions,
    label: 'Interventions',
  },
];

export default templateTypeOptions;
