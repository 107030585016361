import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { validate } from '../form';
import Button from '~/ui/components/common/Button';
import Textarea from '~/ui/components/inputs/Textarea';
import { IFormValues } from '../types';
import Input from '~/ui/components/inputs/Input';
import templateTypeOptions from '~/ui/constants/templateTypeOptions';
import Select from '~/ui/components/inputs/Select';

interface IProps {
  initialValues: IFormValues;
  isSaving: boolean;
  onSaveClick: (data: IFormValues) => void;
  onCancelClick: () => void;
}

const TemplateForm = ({
  initialValues,
  isSaving,
  onSaveClick,
  onCancelClick,
}: IProps): ReactElement => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSaveClick)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name="name" register={register} errors={errors} label="Name *" />
        </Grid>
        <Grid item xs={6}>
          <Select
            name="typeId"
            control={control}
            errors={errors}
            options={templateTypeOptions}
            label="Type *"
          />
        </Grid>
        <Grid item xs={12}>
          <Textarea name="content" register={register} errors={errors} label="Content *" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                disabled={isSaving}
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                isLoading={isSaving}
                disabled={isSaving}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default TemplateForm;
