import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTemplatesByTypeId } from '../types';

const onGetTemplatesByTypeId: IThunkGetTemplatesByTypeId = thunk(
  // @ts-ignore
  async ({ setTemplatesByTypeId, reset }, typeId) => {
    reset();

    const { data } = await api.templates.getByTypeId(typeId);

    setTemplatesByTypeId({ typeId, items: data });
  },
);

export default onGetTemplatesByTypeId;
