import { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import getApplyCodeEditValues from './form/getApplyCodeEditValues';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';
import Icd10CodeModal from '../../popups/Icd10CodeModal';

import { ICarePlanIcd10Code } from '~/services/api/carePlanIcd10Codes/types';
import { IPagePermissions } from '../../../types';
import styles from './Item.module.scss';

interface IProps {
  patientId: number;
  item: ICarePlanIcd10Code;
  permissions: IPagePermissions;
  onCarePlanChange: () => void;
}

const Item = ({ patientId, item, permissions, onCarePlanChange }: IProps) => {
  const [showEdit, setShowEdit] = useState<number>(null);
  const [showRemove, setShowRemove] = useState<number>(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const { onRemoveCarePlanIcd10Code } = useStoreActions(actions => actions.carePlanIcd10Codes);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const handleRemoveCode = async () => {
    try {
      setIsRemoving(true);
      await onRemoveCarePlanIcd10Code(item.id);
      onCarePlanChange();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{item.typeName}</TableCell>
        <TableCell>{item.icd10CodeName}</TableCell>
        <TableCell>
          <ReadMoreLess content={item.icd10CodeDescription} />
        </TableCell>
        {permissions.canManage && (
          <TableCell align="right">
            <IconButton
              className={styles.iconButton}
              title="Edit"
              onClick={() => setShowEdit(item.id)}
            >
              <CreateIcon className={styles.icon} />
            </IconButton>
            <IconButton
              className={styles.iconButton}
              title="Remove"
              onClick={() => setShowRemove(item.id)}
            >
              <DeleteIcon className={styles.icon} />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {Boolean(showEdit) && (
        <Icd10CodeModal
          mode="edit"
          patientId={patientId}
          currentEditCarePlanIcd10Code={item}
          applyCodeInitialValues={getApplyCodeEditValues(item)}
          onClose={() => setShowEdit(null)}
          onCarePlanChange={onCarePlanChange}
        />
      )}
      {showRemove && (
        <ConfirmModal
          title="Remove ICD 10 Code"
          description="Are you sure you want to remove current ICD 10 Code?"
          isLoading={isRemoving}
          onClose={() => setShowRemove(null)}
          onConfirm={handleRemoveCode}
        />
      )}
    </>
  );
};

export default Item;
